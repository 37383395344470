body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiInputBase-inputSizeSmall {
    box-sizing: border-box !important;
    padding: 16px 12px 16px 12px !important;
    flex-grow: 1;
    width: 0;
}

.MuiInputLabel-sizeSmall:not(.MuiInputLabel-shrink) {
    transform: translate(14px, 4px) scale(1) !important;
}

.MuiInputBase-root > input {
    box-sizing: border-box !important;
    padding: 20px 12px 20px 12px !important;
    flex-grow: 1;
    width: 0;
}

.MuiInputLabel-formControl:not(.MuiInputLabel-shrink) {
    transform: translate(14px, 8px) scale(1) !important;
}

img {
    user-drag: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.text-readonly {
    pointer-events: none;
    tab-index: -1;
}